<template>
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2021 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10">
              <li>Hill Air Force Base</li>
              <li>Lawrence Livermore National Laboratory (LLNL)</li>
              <li>
                National Center Explosives Training and Research Redstone
                Arsenal (NCETR)
              </li>
              <li>
                New Mexico Tech - Energetic Materials Research and Testing
                Center (EMRTC)
              </li>
              <li>Northrop Grumman Innovation Systems - ABL</li>
              <li>Northrop Grumman Innovation Systems - Bacchus</li>
              <li>Northrop Grumman Innovation Systems - Corporate</li>
              <li>Northrop Grumman Innovation Systems - Promontory</li>
              <li>Olin- Winchester</li>
              <li>Oklahoma Sate University - Center for Health Sciences</li>
              <li>Safety Management Services, Inc.</li>
              <li>Sandia National Laboratories</li>
              <li>Sporting Arms and Ammunition Manufacturers' Institute</li>
              <li>
                US Department of Homeland Security/ Transportation Security
                Laboratory
              </li>
              <li>Tooele Army Depot</li>
            </ul>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2021 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F00%20-%20Welcome%20-%20Bob%20Ford.pdf?alt=media&token=5581e66c-cbb2-42d7-9aec-d977645cedc7"
                        >Welcome</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F01%20Standardization%20and%20the%20ET%20Users%20Group%20Charter.pdf?alt=media&token=286a1361-1252-4030-8846-fa6a3224c8f1"
                        >Standardization and the ET Users Group Charter</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F02%20Basis%20for%20Recommended%20Distances%20for%20Confined%20HD13v4.pdf?alt=media&token=0fe36f1d-a93d-4b5e-ac68-b937163e66b4"
                        >HD 1.3 Confined vs Unconfined Results</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F03%20Updated%20Website.pdf?alt=media&token=2fe5283a-85b5-4df7-acac-acebfbad4859"
                        >Updated ETUG Website</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F04%20Review%20of%20Round%20Robin%20Objectives%20and%20Results.pdf?alt=media&token=5214f8be-3144-4e6b-accb-bb832fb99d7f"
                        >Review of Round Robin Objectives and Results</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F05%20PUB002075%20ETUG%202021%20Koenen.pdf?alt=media&token=56a05eec-41f1-4993-889a-35f327aa45b8"
                        >NGC-Promontory Koenen Round Robin Results</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F06%20EMRTC_RR2021_Koenen.pdf?alt=media&token=cc8a6873-408b-472b-aafd-caf589bb4e8e"
                        >EMRTC Koenen Round Robin Results</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F07%20Number%208%20Detonator%20to%20Simulate%20In-Process%20Shock%20Stimulus.pdf?alt=media&token=3cc4c627-63ed-42f2-b141-66a73c484d75"
                        >Number 8 Detonator to Simulate In-Process Shock
                        Stimulus Round Robin</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F08%20ETUG%202019%20History%20of%20Explosive%20Classification%20(Hill).pdf?alt=media&token=e3c47e69-d8fc-46d2-8379-faf41417cedb"
                        >History of Explosive Classification (Hill)</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F09%20-%20Explosives%20Classification%20Stewardships%20and%20Application%20Overview.pdf?alt=media&token=0490fcd2-8112-4bf4-abd9-2861cbbf7f0f"
                        >Explosives Classification Stewardships and Application
                        Overview</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F10%20GHS%202.1%20Revision%20Overview.pdf?alt=media&token=10813ec7-96de-4679-848a-6ba30a0a0064"
                        >GHS 2.1 Revision Overview</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F11%20Key%20Parameters-%20Inprocess%20Scenarios%20and%20Testing.pdf?alt=media&token=b389e355-566b-4f5c-84de-6b4381c40045"
                        >Key Parameters- Inprocess Scenarios and Testing</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F12%20-%20In-Process%20Classification%20Protocol%20-%20Kirt%20Sasser.pdf?alt=media&token=9b1e3d47-9eac-4fef-ad5b-bbdbba60363a"
                      >
                        In-Process Classification Protocol</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F13%20IPC%20Greater%20IPC%20Resolution%20(Rev%201).pdf?alt=media&token=5fc014f5-486b-443e-a4bf-af2d00cddfe9"
                        >Greater In-Process Classification Resolution</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F15%20-%20ETUG%20Working%20Group%20Follow-up%20SP%208451%20Energy%20Density.pdf?alt=media&token=5321a40c-e3bf-479e-8644-d50e719206a1"
                        >ETUG Working Group Follow-up SP 8451 Energy Density</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F16%20-%20Sensitivity%20Test%20Equipment%20Maintenance%20%20-%20Jason%20Ford.pdf?alt=media&token=7ea70171-316b-460d-8a5f-aaf0eb0a9ff3"
                        >Sensitivity Test Equipment Maintenance</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F17%20ETUG%20Meeting%202021%20PRES%20827679%20NA.pdf?alt=media&token=26f3ec9f-3379-433a-8739-76cde40ebfec"
                        >DSC Sample Size Effects</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F18%20Wilkins_DHS_TSL_ETUG_Presentation_Updated_10_8_2021.pdf?alt=media&token=5e6b8eea-0a0a-46fb-a66d-afd43431fac8"
                        >Small-Scale Sensitivity Testing at the TSL</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F19%20-SAND2021-11949%20PE%20-%20Introducing%20the%20LEESA.pdf?alt=media&token=8bf195ab-549b-4934-946b-f53348539881"
                        >Introducing the LEESA</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F20%20-%20Challenges%20With%20Relative%20Safety_ETUG%20Meeting%202021_FINAL-Anderson.pdf?alt=media&token=5f76f24f-11ff-447d-a88d-1a021718d805"
                        >Challenges With Relative Safety</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F21%20-%20Thermite%20Reactivity.pdf?alt=media&token=8e21ca9f-1936-4f76-9298-e239ddfc867e"
                        >Thermite Reactivity</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F22%20-%20Koenen%20Tubes%20and%20the%20UN%20Manual%20Update%20-%20Jason%20Ford.pdf?alt=media&token=46c987a4-a426-4e3d-b56a-f2ff498e53b3"
                        >Koenen Tubes and the UN Manual Update</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F23%20-%2070%20kbar%20PMMA%20Detonation%20Velocity.pdf?alt=media&token=e3e470ba-c578-44e0-b59a-d4d906734684"
                        >70 kbar PMMA Detonation Velocity</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2021%2F24%20TNT%20Equivalency%20Points%20for%20HD1.3.pdf?alt=media&token=28cd622d-3152-4dc6-938f-49c775a778ba"
                        >TNT Equivalency Not Appropriate for HD1.3</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
